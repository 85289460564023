import HeaderForm from "../../../../../components/headerForm";
import quantidade from "../../../../../helpers/QuantidadeFormularios";
import Form from "../../../../../components/form/Form";
import React, {useEffect, useState} from "react";
import Button from "../../../../../components/button/Button";
import processosApi from "../../../../../services/processosApi";
import {aw} from "@fullcalendar/core/internal-common";
import Alert from "../../../../../helpers/Alert";
import Input from "../../../../../components/input/Input";
import InputSelect from "../../../../../components/input/InputSelect";
import clientesApi from "../../../../../services/clientesApi";

export interface IDadosAcompantes{
    processo?: any;
    setTelaNumero?: any;
    telanumero?: any;
}

export default function DadosAcompantes({processo, setTelaNumero, telanumero}: IDadosAcompantes) {
    const [acompanhantesNome, setAcompanhantesNome] = useState("");
    const [acompanhantesVistosOpt, setAcompanhantesVistosOpt]: any = useState([]);
    const [acompanhantesVistosSelected, setAcompanhantesVistosSelected]: any = useState([]);

    useEffect(()=>{
        getMembros()
    },[])
    const getMembros = async () => {

        const {data, isError} = await processosApi.membros(
            processo.id_processo_responsavel ?
                processo.id_processo_responsavel
                :
                processo.id
        );
        if (isError){
            if (typeof data.data === 'object') {
                Object.keys(data.data.errors).forEach(function(key, index) {
                    Alert.error(data.data.errors[key][0]);
                });
                return;
            }
            return Alert.error(data.data.message? data.data.message : data.data);
        }
        const response = data.data;

        if (Object.values(response).length){
            const arrayVistosOpt: { value: any; label: any; }[] = [];
            const arrayVistosSelected: { value: any; label: any; }[] = [];
            let nome = '';
            response.map((res: any, index: number) => {

                if (res.possui_visto){
                    arrayVistosSelected.push({ value: res.id, label: res.nome })
                }
                arrayVistosOpt.push({ value: res.id, label: res.nome })
                if (index){
                    if (!nome.length){
                        nome += res.nome;
                    }else{
                        nome += ', '+res.nome;
                    }
                }
            });
            setAcompanhantesVistosSelected(arrayVistosSelected);
            setAcompanhantesVistosOpt(arrayVistosOpt);
            setAcompanhantesNome(nome);
        }else{
            setAcompanhantesNome('VOCÊ NÃO POSSUI ACOMPANHANTES');
        }
    }

    async function onSubmit() {
        const processo_id = processo.id_processo_responsavel ? processo.id_processo_responsavel : processo.id;
        const clientes_id = acompanhantesVistosSelected.map((acompanhante: any) => acompanhante.value);
        const params = {
            'processo_id': processo_id,
            'clientes_id': clientes_id,
        }

        const {data, isError} = await clientesApi.alteraVistoClientes(params);

        if (isError){
            if (typeof data.data === 'object') {
                Object.keys(data.data.errors).forEach(function(key, index) {
                    Alert.error(data.data.errors[key][0]);
                });
                return;
            }
            return Alert.error(data.data.message? data.data.message : data.data);
        }
        setTelaNumero(telanumero+1)
    }

    return(
        <>
            <HeaderForm
                titulo={'Acompanhantes'}
                numeroPaginalAtual={telanumero}
                numeroTotal={quantidade[processo.tipo_visto_id]}
                percentual={processo.percet_conclusao}
            />
            <Form  onSubmit={() => onSubmit()}>
                <div className={'p-5'}>
                    <Input
                        className={'md:col-span-1'}
                        label={'Acompanhantes'}
                        name={'acompanhantes'}
                        disabled={true}
                        value={acompanhantesNome}
                    />
                </div>
                <div className={'grid md:grid-cols-1  p-5'}>
                    <InputSelect
                        placeholder={'Selecione'}
                        label={"Membros que já possuem vistos"}
                        isMulti={true}
                        selected={acompanhantesVistosSelected}
                        options={acompanhantesVistosOpt}
                        onChangeSelect={(e: any) => setAcompanhantesVistosSelected(e)}
                    />
                </div>
                <div className={'border-2'}></div>
                <div className={'flex justify-between p-5'}>
                    <Button
                        onClick={() => setTelaNumero(telanumero-1)}
                        className={'w-[164px] h-[48px] bg-white text-gray-800 border border-gray-500 font-semibold'}
                    >
                        Voltar
                    </Button>
                    <Button
                        type={'submit'}
                        className={'w-[164px] h-[48px] bg-[#082a60] text-white font-semibold'}
                    >
                        Continuar
                    </Button>
                </div>
            </Form>
        </>
    )
}