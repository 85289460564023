import { FaCheck } from "react-icons/fa6";
import {QueryParams} from "../../../types";
import Input from "../../../components/input/Input";
import {ReactComponent as AddIcon} from "../../../assets/icone/add.svg";
import Button from "../../../components/button/Button";
import React, {useEffect, useState} from "react";
import Stepper from "../../../components/stepper";
import Dates from "../../../helpers/Dates";
import cronogramaApi from "../../../services/cronogramaApi";
import situacaoProcessoApi from "../../../services/situacaoProcessoApi";
import processosApi from "../../../services/processosApi";
import Alert from "../../../helpers/Alert";
import Storage from "../../../helpers/Storage";
export interface IProcessosLinhaTempo {
    situacoes: any;
    processoIdResponsavel?: any;
    processoId: any;
    cronogramaAtual: any;
    setAtualizar: any;
    cliente?: boolean;
    quantidadeProcessoResponsavel?: any;
    cronogramaProcesso?: any;
}
export default function ProcessosLinhaTempo(
    {
        situacoes,
        processoId,
        quantidadeProcessoResponsavel,
        cronogramaAtual,
        setAtualizar,
        cliente,
        cronogramaProcesso
    }: IProcessosLinhaTempo) {

    const userData: any = Storage.getUserData();
    const hoje = new Date().toISOString().slice(0, 10);
    const [cronograma, setCronograma] = useState(cronogramaAtual.data_finalizacao ? {} : cronogramaAtual);
    const [situacaoFinal, setSituacaoFinal] = useState(situacoes);
    const [etapaFinalizado, setEtapaFinalizado] = useState(cronogramaAtual ? cronogramaAtual.data_finalizacao ? true : false : false);
    const [diasFinalizacao, setDiasFinalizacao] = useState(cronogramaAtual.data_finalizacao ? '' : cronogramaAtual.dias_prazo_finalizacao);
    const [situacaoAtual, setSituacaoAtual]: any = useState(cronogramaAtual.situacao_processo);

    const [idStepperSelected, setStepperSelected] = useState(cronogramaAtual.situacao_id);

    const [dataFinalizacao, setDataFinalizacao] = useState(cronogramaAtual ? cronogramaAtual.data_prazo_finalizacao : '');
    const onSaveDate = async () => {
        const e = dataFinalizacao;

        if (e){
            if (Object.keys(cronograma).length === 0){
                const newCronograma = await cronogramaApi.create({
                    processo_id: processoId,
                    situacao_id: situacaoAtual.id,
                    data_prazo_finalizacao: e,
                    dias_prazo_finalizacao: Dates.difDay(hoje, e).toString()
                });

                if (newCronograma.isError){
                    if (typeof newCronograma.data === 'object') {
                        Object.keys(newCronograma.data.errors).forEach(function(key, index) {
                            Alert.error(newCronograma.data.errors[key][0]);
                        });
                        return;
                    }
                    return Alert.error(newCronograma.data.message? newCronograma.data.message : newCronograma.data);
                }
                cronogramaProcesso.push(newCronograma.data.data)
                setCronograma(newCronograma.data.data);
                Alert.success('Prazo para finalização salva');
            }else{
                const updateArray = {
                    data_prazo_finalizacao: e,
                    dias_prazo_finalizacao: Dates.difDay(hoje, e).toString()
                }
                const updateCronograma = await cronogramaApi.update(cronograma.id, updateArray);
                if (updateCronograma.isError){
                    if (typeof updateCronograma.data === 'object') {
                        Object.keys(updateCronograma.data.errors).forEach(function(key, index) {
                            Alert.error(updateCronograma.data.errors[key][0]);
                        });
                        return;
                    }
                    return Alert.error(updateCronograma.data.message? updateCronograma.data.message : updateCronograma.data);
                }
                Alert.success('Prazo para finalização salva');
            }

            const indexSituacao = situacaoFinal.findIndex((st: any) => st.id === situacaoAtual.id);
            const novaSituacao = [...situacaoFinal];

            novaSituacao[indexSituacao]['data_finalizacao'] = e
            setSituacaoFinal(novaSituacao)
        }
    }

    const concluirEtapa = async () => {
        const confirm = await Alert.confirm('Deseja realmente concluir essa etapa do processo?', 'Concluir etapa');
        if (!confirm) return;
        let newData = new Date()
        let data2 = new Date(newData.valueOf() - newData.getTimezoneOffset() * 60000);
        let dataBase = data2.toISOString().replace(/\.\d{3}Z$/, '');
        const dataUpdate = { data_finalizacao: dataBase}
        if (cronograma){

            const editCronograma = await cronogramaApi.update(cronograma.id, dataUpdate);

            const newSituacaoFinal = [...situacaoFinal];
            const indexSituacao = newSituacaoFinal.findIndex((st: any) => st.id === cronograma.situacao_id);
            if (indexSituacao !== -1){
                newSituacaoFinal[indexSituacao]['status'] = 'concluido';
            }
            setSituacaoFinal(newSituacaoFinal)
            cronogramaProcesso.push(editCronograma.data.data)
            setEtapaFinalizado(true);
            Alert.success('Etapa concluida');
            /*const newSituacaoFinal = [...situacaoFinal];
            const indexSituacao = newSituacaoFinal.findIndex((st: any) => st.id === situacaoAtual.id);
            if (indexSituacao !== -1){
                newSituacaoFinal[indexSituacao]['status'] = 'concluido';
            }
            cronogramaProcesso.push(editCronograma.data.data)
            setCronograma(editCronograma.data.data);
            setSituacaoFinal(newSituacaoFinal)
            Alert.success('Etapa concluida');*/
        }else{
            const newCronograma = await cronogramaApi.create({
                processo_id: processoId,
                situacao_id: situacaoAtual.id,
                data_prazo_finalizacao: dataBase,
                data_finalizacao: dataBase,
                dias_prazo_finalizacao: '0'
            });
            if (newCronograma.isError){
                if (typeof newCronograma.data === 'object') {
                    Object.keys(newCronograma.data.errors).forEach(function(key, index) {
                        Alert.error(newCronograma.data.errors[key][0]);
                    });
                    return;
                }
                return Alert.error(newCronograma.data.message? newCronograma.data.message : newCronograma.data);
            }

            const newSituacaoFinal = [...situacaoFinal];
            const indexSituacao = newSituacaoFinal.findIndex((st: any) => st.id === situacaoAtual.id);
            if (indexSituacao !== -1){
                newSituacaoFinal[indexSituacao]['status'] = 'concluido';
            }
            cronogramaProcesso.push(newCronograma.data.data)
            setCronograma(newCronograma.data.data);
            setSituacaoFinal(newSituacaoFinal)
            Alert.success('Etapa concluida');
        }
        /*let newData = new Date()
        let data2 = new Date(newData.valueOf() - newData.getTimezoneOffset() * 60000);
        let dataBase = data2.toISOString().replace(/\.\d{3}Z$/, '');
        const dataUpdate = { data_finalizacao: dataBase}
        const data = await cronogramaApi.update(cronograma.id, dataUpdate);
        if (cronograma.situacao_id !== 6 && cronograma.situacao_id !== 7) {
            const newCronograma = await cronogramaApi.create({
                processo_id: cronograma.processo_id,
                situacao_id: cronograma.situacao_id + 1,

            });
            setCronograma(newCronograma.data.data);
        }
        setDataFinalizacao('');
        const [situacaoProcesso, processo] = await  Promise.all([situacaoProcessoApi.listAll(), processosApi.one(processoId)]);
        cronograma.data_finalizacao = dataBase;
        let arraySituacao = [];
        if (!situacaoProcesso.isError){
            let em_andamento_encontrado = false;
            // @ts-ignore
            arraySituacao = situacaoProcesso.data.data.map((situacao, index) => {
                if (processo.data.data.ultimo_cronograma_concluido){
                    if (processo.data.data.ultimo_cronograma_concluido.situacao_id === 6){
                        if (situacao.id === 6){
                            return {
                                id: situacao.id,
                                nome: situacao.nome,
                                status: 'concluido', //andamento, concluido, aFazer
                            }
                        }else{
                            if (situacao.id === 7){
                                return {
                                    id: situacao.id,
                                    nome: situacao.nome,
                                    status: 'aFazer', //andamento, concluido, aFazer
                                }
                            }else{
                                return {
                                    id: situacao.id,
                                    nome: situacao.nome,
                                    status: 'concluido', //andamento, concluido, aFazer
                                }
                            }

                        }
                    }

                }

                if (processo.data.data.ultimo_cronograma){
                    if (processo.data.data.ultimo_cronograma.situacao_id === situacao.id){
                        em_andamento_encontrado = true;
                        return {
                            id: situacao.id,
                            nome: situacao.nome,
                            status: 'andamento', //andamento, concluido, aFazer
                        }
                    }else{
                        if (em_andamento_encontrado){
                            return {
                                id: situacao.id,
                                nome: situacao.nome,
                                status: 'aFazer', //andamento, concluido, aFazer
                            }
                        }else{
                            return {
                                id: situacao.id,
                                nome: situacao.nome,
                                status: 'concluido', //andamento, concluido, aFazer
                            }
                        }
                    }
                }

                if (em_andamento_encontrado){
                    return {
                        id: situacao.id,
                        nome: situacao.nome,
                        status: 'aFazer', //andamento, concluido, aFazer
                    }
                }
                em_andamento_encontrado = true;
                return {
                    id: situacao.id,
                    nome: situacao.nome,
                    status: 'andamento', //andamento, concluido, aFazer
                }
            })
        }
        setSituacaoFinal(arraySituacao);*/
    }

    const replicarPrincipal = async () => {

        const confirm = await Alert.confirm('Deseja replicar a data dessa etapa nos demais processos vinculados á esse cliente?', 'Replicar data?');
        if (!confirm) return;

        const idsProcessosResponsavel = quantidadeProcessoResponsavel.map((processo: any) => processo.id);

        const data = {
            'ids_Processos': idsProcessosResponsavel,
            'id_situacao': cronograma.situacao_id,
            'data_finalizacao': dataFinalizacao,
            'dias_prazo_finalizacao': Dates.difDay(hoje, dataFinalizacao),
        }
        //console.log(data)
        const resp = await cronogramaApi.cronogramasReplicar(data);
        if (resp.isError){
            if (typeof resp.data === 'object') {
                Object.keys(resp.data.errors).forEach(function(key, index) {
                    Alert.error(resp.data.errors[key][0]);
                });
                return;
            }
            return Alert.error(resp.data.message? resp.data.message : resp.data);
        }
        Alert.success('Cronogramas atualizados');
    }

    const onClickStepper = (data: any) => {
        const newData = [...situacaoFinal];

        const dataPrazos = cronogramaProcesso.find((cronograma: any) => cronograma.situacao_id === data.id);

        /*console.log(cronograma, dataPrazos, data, cronogramaProcesso);
        console.log(situacoes);*/
        let situcaoAtualData = {};

        newData.map((situacao: any, index: number) => {
            if (situacao.status === 'andamento'){
                newData[index].status = 'aFazer';
            }
            if (data.status !== 'concluido'){
                if (situacao.id === data.id){
                    situcaoAtualData = situacao;
                    newData[index].status = 'andamento';
                }
            }

        })

        //console.log(dataPrazos, newData);
        if (dataPrazos){
            setEtapaFinalizado(!!dataPrazos.data_finalizacao);
            setDiasFinalizacao(dataPrazos.dias_prazo_finalizacao);
            setDataFinalizacao(dataPrazos.data_prazo_finalizacao)
        }else{
            setDiasFinalizacao('');
            setDataFinalizacao('');
            setEtapaFinalizado(false);
        }
        setSituacaoFinal(newData);
        setSituacaoAtual(situcaoAtualData);
        //console.log(dataPrazos)
        //setCronograma(dataPrazos);

        setStepperSelected(data.id)

    }

    /*useEffect(() => {
        console.log(Dates.addDaysToDate(hoje, 10))
    },[])*/
    const changeDay = (data: any) =>{
        setDiasFinalizacao(data);
        setDataFinalizacao(Dates.addDaysToDate(hoje, parseInt(data)))
    }

    return(
        <>
            <div className={'md:h-[18vh]'}>

                <div className="sm:mx-4 sm:p-4 sm:mb-5">
                    <div className="flex md:flex-row xs:flex-col md:items-center xs:p-4 gap-2">
                        {
                            situacaoFinal.map((situacao: any, index: number) => {

                                return(
                                    <Stepper
                                        idStepperSelected={idStepperSelected}
                                        nome={situacao.nome}
                                        arrayLengt={situacoes.length}
                                        index={index}
                                        situacao={situacao.status}
                                        data={situacao}
                                        onClick={onClickStepper}
                                    />
                                )
                            })
                        }

                    </div>
                </div>
            </div>
            {
                cronograma && cronograma.situacao_id === 7 ? <div className={'mt-5'}></div>
                    :
                    <div className={'flex md:mt-[5rem]'}>
                        <div className={'w-full grid grid-cols-7 gap-4 p-3 '}>
                            <Input
                                disabled={true}
                                className={'md:col-span-2 xs:col-span-7'}
                                label={'Prazo para finalização'}
                                type={'date'}
                                name={'prazo_finalizacao'}
                                setValue={e => setDataFinalizacao(e)}
                                value={dataFinalizacao}

                            />
                            <Input
                                disabled={userData.usuario.tipo_id === 3 ? true : etapaFinalizado ? true : (cliente ? true : (cronograma ? cronograma.situacao_id === 6 || cronograma.situacao_id === 7 : false))}
                                className={'md:col-span-2 xs:col-span-7'}
                                label={'Dias para finalização'}
                                name={'prazo_finalizacao'}
                                setValue={e => changeDay(e)}
                                /*value={etapaFinalizado ? '0' : (dataFinalizacao ? Dates.difDay(hoje, dataFinalizacao) : '')}*/
                                value={diasFinalizacao}
                            />
                            {
                                userData.usuario.tipo_id === 3 ? '' :
                                cliente ? '' :
                                    !etapaFinalizado ?
                                    <div className={'md:col-span-1 xs:col-span-7 flex items-end'}>
                                        <Button  onClick={onSaveDate} className={'flex bg-[#E4EDFC] w-[200px] h-[48px]'}>
                                            Salvar datas
                                        </Button>
                                    </div>
                                    :
                                    ''
                            }


                            {
                                userData.usuario.tipo_id === 3 ? '' :
                                cliente ? '' :
                                quantidadeProcessoResponsavel.length ?
                                    !etapaFinalizado ?
                                        <div className={'md:col-span-1 xs:col-span-7 flex items-end'}>
                                            <Button  onClick={replicarPrincipal} className={'flex bg-[#29D26F] text-white md:w-[200px] h-[48px]'}>
                                                Clonar datas
                                            </Button>
                                        </div>
                                        :
                                        ''
                                    :
                                    ''
                            }

                            {
                                userData.usuario.tipo_id === 3 ? '' :
                                cliente ? ''
                                    :
                                    !etapaFinalizado ?
                                    <div className={'md:col-span-1 xs:col-span-7 flex items-end'}>
                                        <Button  onClick={concluirEtapa} className={'flex bg-[#082a60] text-white md:w-[146px] h-[48px]'}>
                                            Concluir Etapa
                                        </Button>
                                    </div>
                                        :
                                        ''
                            }

                        </div>

                    </div>
            }

        </>
    )
}