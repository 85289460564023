import HeaderForm from "../../../../../components/headerForm";
import quantidade from "../../../../../helpers/QuantidadeFormularios";
import Form from "../../../../../components/form/Form";
import Button from "../../../../../components/button/Button";
import React, {useEffect, useState} from "react";
import Input from "../../../../../components/input/Input";
import dadosDosPaisApi from "../../../../../services/dadosDosPaisApi";
import Alert from "../../../../../helpers/Alert";
import processosApi from "../../../../../services/processosApi";

export interface IDadosFamiliaresCa{
    processo?: any;
    setProcesso?: any;
    setTelaNumero?: any;
    telanumero?: any;
    setStateDadosDosPais?: any;
}

export default function DadosFamiliaresCa({processo, setProcesso, setTelaNumero, telanumero, setStateDadosDosPais}: IDadosFamiliaresCa) {
    const [state, setState] = useState({
        id: '',
        processo_id: processo.id,
        nome_pai: '',
        data_nascimento_pai: '',
        pais_nascimento_pai: '',
        estado_civil_pai: '',
        endereco_pai: '',
        ocupacao_pai: '',
        viaja_junto_pai: 0,

        nome_mae: '',
        data_nascimento_mae: '',
        pais_nascimento_mae: '',
        estado_civil_mae: '',
        endereco_mae: '',
        ocupacao_mae: '',
        viaja_junto_mae: 0,

        viajante_possui_filhos: 0,

    });
    async function onSubmit() {
        const {data, isError} = state.id ? await dadosDosPaisApi.update(state.id, state) :  await dadosDosPaisApi.create(state);
        if (isError){
            if (typeof data.data === 'object') {
                Object.keys(data.data.errors).forEach(function(key, index) {
                    Alert.error(data.data.errors[key][0]);
                });
                return;
            }
            return Alert.error(data.data.message? data.data.message : data.data);
        }
        if (!state.id){
            const percent = (telanumero * 100) / quantidade[processo.tipo_visto_id];
            await processosApi.update(processo.id, {percet_conclusao: percent})
            setProcesso({...processo, ['percet_conclusao']: percent})
        }
        Alert.close();
        window.scrollTo(0, 0);
        setStateDadosDosPais(state);

        if (state.viajante_possui_filhos){
            setTelaNumero(telanumero+1);
        }else{
            setTelaNumero(telanumero+2);
        }

    }
    const handleChange = (value: any, input: string) => {
        setState({...state, [input]: value});
    };
    useEffect(() => {
        getDadoDosPais();
    }, []);
    const getDadoDosPais = async () =>{
        const {data, isError} = await dadosDosPaisApi.one(processo.id);
        if (!isError){
            if (Object.values(data.data).length){
                setState(data.data);
            }
        }
    }
    return(
        <>
            <HeaderForm
                titulo={'Dados dos pais'}
                numeroPaginalAtual={telanumero}
                numeroTotal={quantidade[processo.tipo_visto_id]}
                percentual={processo.percet_conclusao}
            />
            <Form  onSubmit={() => onSubmit()}>
                <div className={'p-5'}>
                    <div className={'grid md:grid-cols-3 gap-4 mt-5'}>
                        <Input
                            className={'md:col-span-1'}
                            large={true}
                            label={'Nome completo da mãe'}
                            required={true}
                            name={'nome_mae'}
                            setValue={e => handleChange(e,'nome_mae')}
                            value={state.nome_mae}
                        />
                        <Input
                            required={true}
                            type={'date'}
                            className={'md:col-span-1'}
                            label={'Data de nascimento mãe'}
                            name={'data_nascimento_mae'}
                            setValue={e => handleChange(e,'data_nascimento_mae')}
                            value={state.data_nascimento_mae}
                        />
                        <Input
                            className={'md:col-span-1'}
                            large={true}
                            label={'País de nascimento de sua mãe'}
                            required={true}
                            name={'pais_nascimento_mae'}
                            setValue={e => handleChange(e,'pais_nascimento_mae')}
                            value={state.pais_nascimento_mae}
                        />
                    </div>
                    <div className={'grid md:grid-cols-5 gap-4 mt-5'}>
                        <Input
                            className={'md:col-span-1'}
                            large={true}
                            label={'Estado civil da mãe'}
                            name={'estado_civil_mae'}
                            setValue={e => handleChange(e,'estado_civil_mae')}
                            value={state.estado_civil_mae}
                        />
                        <Input
                            required={true}
                            className={'md:col-span-2'}
                            label={'Endereço atual de sua mãe'}
                            name={'endereco_mae'}
                            setValue={e => handleChange(e,'endereco_mae')}
                            value={state.endereco_mae}
                            legenda={'Caso seja falecido, informar a data e a cidade.'}
                        />
                        <Input
                            className={'md:col-span-1'}
                            large={true}
                            label={'Ocupação'}
                            required={true}
                            name={'ocupacao_mae'}
                            setValue={e => handleChange(e,'ocupacao_mae')}
                            value={state.ocupacao_mae}
                        />
                        <div>
                            <div className={'flex flex-col'}>
                                <label
                                    className="text-sm font-medium text-gray-900 dark:text-gray-300"
                                >
                                    Sua mãe vai viajar junto
                                </label>
                                <div className={'flex  gap-4 mt-[1rem]'}>
                                    <div className={'flex'}>
                                        <input
                                            onChange={e => handleChange(1,'viaja_junto_mae')}
                                            value={1}
                                            id="checked-viaja_junto_mae-s"
                                            type="radio"
                                            checked={state.viaja_junto_mae === 1 }
                                            className="w-5 h-5 text-[#061f46] bg-gray-100 accent-[#061f46] border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-[#fff] dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                        />
                                        <label
                                            htmlFor="checked-viaja_junto_mae-s"
                                            className="ms-2 text-[16px] font-medium text-gray-900 dark:text-gray-300"
                                        >
                                            Sim
                                        </label>
                                    </div>
                                    <div className={'flex'}>
                                        <input
                                            checked={state.viaja_junto_mae === 0 }
                                            onChange={e => handleChange(0,'viaja_junto_mae')}
                                            id="checked-viaja_junto_mae-n"
                                            type="radio"
                                            value={0}
                                            className="w-5 h-5 text-blue-600 accent-[#061f46] bg-gray-100 border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                        />
                                        <label
                                            htmlFor="checked-viaja_junto_mae-n"
                                            className="ms-2 text-[16px] font-medium text-gray-900 dark:text-gray-300"
                                        >
                                            Não
                                        </label>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>

                    <div className={'grid md:grid-cols-3 gap-4 mt-5'}>
                        <Input
                            className={'md:col-span-1'}
                            large={true}
                            label={'Nome completo da pai'}
                            required={true}
                            name={'nome_pai'}
                            setValue={e => handleChange(e,'nome_pai')}
                            value={state.nome_pai}
                        />
                        <Input
                            required={true}
                            type={'date'}
                            className={'md:col-span-1'}
                            label={'Data de nascimento pai'}
                            name={'data_nascimento_pai'}
                            setValue={e => handleChange(e,'data_nascimento_pai')}
                            value={state.data_nascimento_pai}
                        />
                        <Input
                            className={'md:col-span-1'}
                            large={true}
                            label={'País de nascimento de seu pai'}
                            required={true}
                            name={'pais_nascimento_pai'}
                            setValue={e => handleChange(e,'pais_nascimento_pai')}
                            value={state.pais_nascimento_pai}
                        />
                    </div>
                    <div className={'grid md:grid-cols-5 gap-4 mt-5'}>
                        <Input
                            className={'md:col-span-1'}
                            large={true}
                            label={'Estado civil do pai'}
                            name={'estado_civil_pai'}
                            setValue={e => handleChange(e,'estado_civil_pai')}
                            value={state.estado_civil_pai}
                        />
                        <Input
                            required={true}
                            className={'md:col-span-2'}
                            label={'Endereço atual do seu pai'}
                            name={'endereco_pai'}
                            setValue={e => handleChange(e,'endereco_pai')}
                            value={state.endereco_pai}
                            legenda={'Caso seja falecida, informar a data e a cidade.'}
                        />
                        <Input
                            className={'md:col-span-1'}
                            large={true}
                            label={'Ocupação'}
                            required={true}
                            name={'ocupacao_pai'}
                            setValue={e => handleChange(e,'ocupacao_pai')}
                            value={state.ocupacao_pai}
                        />
                        <div>
                            <div className={'flex flex-col'}>
                                <label
                                    className="text-sm font-medium text-gray-900 dark:text-gray-300"
                                >
                                    Seu pai vai viajar junto
                                </label>
                                <div className={'flex  gap-4 mt-[1rem]'}>
                                    <div className={'flex'}>
                                        <input
                                            onChange={e => handleChange(1,'viaja_junto_pai')}
                                            value={1}
                                            id="checked-viaja_junto_pai-s"
                                            type="radio"
                                            checked={state.viaja_junto_pai === 1 }
                                            className="w-5 h-5 text-[#061f46] bg-gray-100 accent-[#061f46] border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-[#fff] dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                        />
                                        <label
                                            htmlFor="checked-viaja_junto_pai-s"
                                            className="ms-2 text-[16px] font-medium text-gray-900 dark:text-gray-300"
                                        >
                                            Sim
                                        </label>
                                    </div>
                                    <div className={'flex'}>
                                        <input
                                            checked={state.viaja_junto_pai === 0 }
                                            onChange={e => handleChange(0,'viaja_junto_pai')}
                                            id="checked-viaja_junto_pai-n"
                                            type="radio"
                                            value={0}
                                            className="w-5 h-5 text-blue-600 accent-[#061f46] bg-gray-100 border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                        />
                                        <label
                                            htmlFor="checked-viaja_junto_pai-n"
                                            className="ms-2 text-[16px] font-medium text-gray-900 dark:text-gray-300"
                                        >
                                            Não
                                        </label>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>

                    <div className={'grid grid-cols-1 gap-4 mt-5'}>
                        <div className={'flex flex-col'}>
                            <label
                                className="text-sm font-medium text-gray-900 dark:text-gray-300"
                            >
                                Você possui filhos
                            </label>
                            <div className={'flex  gap-4 mt-[1rem]'}>
                                <div className={'flex'}>
                                    <input
                                        onChange={e => handleChange(1,'viajante_possui_filhos')}
                                        value={1}
                                        id="checked-viajante_possui_filhos-s"
                                        type="radio"
                                        checked={state.viajante_possui_filhos === 1 }
                                        className="w-5 h-5 text-[#061f46] bg-gray-100 accent-[#061f46] border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-[#fff] dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    />
                                    <label
                                        htmlFor="checked-viajante_possui_filhos-s"
                                        className="ms-2 text-[16px] font-medium text-gray-900 dark:text-gray-300"
                                    >
                                        Sim
                                    </label>
                                </div>
                                <div className={'flex'}>
                                    <input
                                        checked={state.viajante_possui_filhos === 0 }
                                        onChange={e => handleChange(0,'viajante_possui_filhos')}
                                        id="checked-viajante_possui_filhos-n"
                                        type="radio"
                                        value={0}
                                        className="w-5 h-5 text-blue-600 accent-[#061f46] bg-gray-100 border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    />
                                    <label
                                        htmlFor="checked-viajante_possui_filhos-n"
                                        className="ms-2 text-[16px] font-medium text-gray-900 dark:text-gray-300"
                                    >
                                        Não
                                    </label>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
                <div className={'border-2'}></div>
                <div className={'flex justify-between p-5'}>
                    <Button
                        onClick={() => setTelaNumero(telanumero - 1)}
                        className={'w-[164px] h-[48px] bg-white text-gray-800 border border-gray-500 font-semibold'}
                    >
                        Voltar
                    </Button>
                    <Button
                        type={'submit'}
                        className={'w-[164px] h-[48px] bg-[#082a60] text-white font-semibold'}
                    >
                        Continuar
                    </Button>
                </div>
            </Form>
        </>
    )
}