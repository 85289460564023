import LabelText from "../../../components/labelText";
import Button from "../../../components/button/Button";
import envioSenhaApi from "../../../services/envioSenhaApi";
import processosApi from "../../../services/processosApi";
import Alert from "../../../helpers/Alert";
import Form from "../../../components/form/Form";
import Input from "../../../components/input/Input";
import Modal from "../../../components/modal/Modal";
import React, {useState} from "react";
import useModal from "../../../hooks/useModal";
import lembretesApi from "../../../services/lembretesApi";

import { FaTrash } from "react-icons/fa";
import Dates from "../../../helpers/Dates";
import Table from "../../../components/table/Table";
import Storage from "../../../helpers/Storage";

export default function ProcessosResumo({resumoData, processoId, setAtualizar, client, onClickForm}: any) {
    const cliente = resumoData.cliente;
    const userData: any = Storage.getUserData();
    const { modalProps, open, close } = useModal();
    const [state, setState] = useState({
        id: null,
        processo_id: processoId,
        lembrete: ''
    });
    const onChange = (e: any) => {
        setState({...state, [e.target.name]: e.target.value})
    }
    async function enviarSenha() {
        Alert.await(`Enviando e-mail...`);

        const dt = {
            id: cliente.usuario_id
        }
        const data = await envioSenhaApi.reenviarSenha(dt);
        if (!data.isError) {
            Alert.success(data.data.message);
        }
        //Alert.close();
    }

    async function cancelarProcesso() {
        const confirm = await Alert.confirm('Tem certeza que deseja cancelar esse processo?', 'Cancelar processo?');
        if (! confirm) return;
        Alert.await('Cancelando processo...');
        const {data, isError} = await processosApi.cancelar(processoId);
        if (!isError) {
            Alert.success(data.message);
        }
        if (data.message === 'Cancelado com sucesso'){
            setTimeout(
                () => setAtualizar(true),
                1000
            );
        }
    }

    async function novoLembrete() {
        Alert.await(`Salvando lembrete`);
        const lembrete = lembretesApi.create(state);
        Alert.success('Lembrete salvo');
        setTimeout(
            () => setAtualizar(true),
            1000
        );
    }

    async function deletarLembrete(id: string) {
        const confirm = await Alert.confirm('Tem certeza que deseja excluir?', 'Excluir lembrete?');
        if (! confirm) return;
        Alert.await('Excluindo sala...');
        const lembrete = lembretesApi.destroy(id);
        Alert.close();
        setTimeout(
            () => setAtualizar(true),
            1000
        );
    }

    const onClickTable = (e: any) => {
        window.open('/processos/38', '_self');
        return false;
    }

    const columns = [
        { label: "NOME", index: "cliente.nome+cliente.sobrenome", type: 'string'}, ,
        { label: "PORCETAGEM", index: "percet_conclusao", type: 'percent' },
        { label: "VISUALIZAR", index: "button", type: 'button', onCkick: onClickTable },
    ];
    return(
        <>
            <div className={'grid grid-cols-6 gap-4 p-3'}>
                <LabelText
                    className={'col-span-2 max-[800px]:col-span-6'}
                    textLael={'Nome do viajante'}
                    contentLabel={`${cliente.nome} ${cliente.sobrenome}`}
                />
                <LabelText
                    className={'max-[800px]:col-span-3'}
                    textLael={'Código'}
                    contentLabel={resumoData.id}
                />
                {
                    client ?
                        <LabelText
                            className={'max-[800px]:col-span-3'}
                            textLael={'Data de nascimento'}
                            contentLabel={Dates.convertEnToBr(cliente.nascimento)}
                        />
                        :
                        <LabelText
                            className={'max-[800px]:col-span-3'}
                            textLael={'Data de criação'}
                            contentLabel={Dates.convertEnToBr(resumoData.created_at, "T")}
                        />
                }
                {/*<LabelText
                    className={'max-[800px]:col-span-3'}
                    textLael={'Data de criação'}
                    contentLabel={Dates.convertEnToBr(resumoData.created_at, "T")}
                />*/}
                <LabelText
                    className={'max-[800px]:col-span-6'}
                    textLael={'Passaporte'}
                    contentLabel={`${resumoData.passaporte ? resumoData.passaporte.numero : '' }`}
                />
                <LabelText
                    className={'max-[800px]:col-span-3'}
                    textLael={'Qtd. de viajantes'}
                    contentLabel={resumoData.quantidade_pessoas === 1 ? resumoData.quantidade_pessoas : (resumoData.quantidade_pessoas + 1)}
                />
            </div>
            <div className={'grid grid-cols-6 gap-4 p-3'}>
                <LabelText
                    className={'col-span-3 max-[800px]:col-span-6'}
                    textLael={'Tipo de visto'}
                    contentLabel={resumoData.tipo_visto.nome}
                />

                <LabelText
                    className={'max-[800px]:col-span-3'}
                    textLael={'País de destino'}
                    contentLabel={resumoData.pais.nome}
                />
                <LabelText
                    className={'max-[800px]:col-span-3'}
                    textLael={'Data ida'}
                    contentLabel={Dates.convertEnToBr(resumoData.data_prevista_viagem)}
                />
                <LabelText
                    className={'max-[800px]:col-span-6'}
                    textLael={'Data volta'}
                    contentLabel={Dates.convertEnToBr(resumoData.data_prevista_retorno)}
                />
            </div>
            {
                client ?
                    <>
                        <div className={'flex p-3'}>
                            <div className={'col-span-1 flex'}>
                                <Button onClick={onClickForm} className={' w-[271px] bg-white bg-[#082a60] text-[#FFF] font-bold'}>Prencher formulario</Button>

                            </div>
                        </div>
                        {
                            resumoData.processos_membros_resumido.length ?
                                <div className={' p-3'}>
                                    <Table
                                        columns={columns}
                                        tableData={resumoData.processos_membros_resumido}
                                        onClick={onClickTable}
                                    />
                                </div>
                                :
                                ''
                        }
                    </>

                    :
                    userData.usuario.tipo_id === 3 ? '' :
                    <>
                        <div className={'grid md:grid-cols-6 gap-4 p-3'}>
                            <LabelText
                                textLael={'Login'}
                                className={'md:col-span-2'}
                                contentLabel={resumoData.cliente.usuario.email}
                            />
                            <Button onClick={enviarSenha} className={'ml-5 w-[164px] bg-white border border-[#082a60] text-[#082a60] font-bold'}>Enviar Senha</Button>
                            <Button
                                onClick={cancelarProcesso}
                                className={'ml-5 w-[164px] bg-white border border-[#c22929] text-[#c22929] font-bold'}
                            >
                                Cancelar
                            </Button>

                        </div>
                        <div className={'flex p-3'}>
                            <div className={'col-span-1 flex'}>
                                <button onClick={() => open()} className={'text-[#082a60] text-[16px] items-center font-bold'}> + Adicionar Lembrete</button>
                            </div>
                        </div>
                        <div>
                            {
                                !client ?
                                    resumoData.lembretes.length ?
                                        <div className={'grid grid-cols-6 gap-4 p-3'}>
                                            {
                                                resumoData.lembretes.map((lembrete: any) => {
                                                    return (
                                                        <div className={'flex w-full col-span-2 items-start'}>
                                                            <textarea
                                                                className={' bg-light border border-border rounded-md outline-none p-2 lg:px-6 w-full'}
                                                                rows={3}
                                                                cols={50}
                                                                disabled={true}
                                                                value={lembrete.lembrete}
                                                            />
                                                            <button onClick={() => deletarLembrete(lembrete.id)} className={'ml-1 text-[#f25151] hover:text-[#f22b2b]'}><FaTrash /></button>
                                                        </div>

                                                    )
                                                })
                                            }

                                        </div>
                                        : ''
                                    :
                                    ''
                            }
                        </div>
                        {
                            resumoData.processos_membros_resumido.length ?
                                <div className={' p-3'}>
                                    <Table
                                        columns={columns}
                                        tableData={resumoData.processos_membros_resumido}
                                        onClick={onClickTable}
                                    />
                                </div>
                                :
                                 ''
                        }

                        <Modal size="lg" title="Novo lembrete" {...modalProps}>
                            <Form onSubmit={novoLembrete}>
                                <div className={'p-3 flex flex-col'}>
                                    <textarea
                                        className={'bg-light border border-border rounded-md outline-none p-2 lg:px-6 w-full'}
                                        rows={5}
                                        cols={50}
                                        name={'lembrete'}
                                        onChange={onChange}
                                        value={state.lembrete ? state.lembrete : ""}
                                    />
                                </div>
                                <div className={'p-3 flex '}>
                                    <Button type='submit' className={'flex bg-[#082a60] text-white w-[146px] h-[48px]'} >
                                        Salvar
                                    </Button>
                                    <Button onClick={close} className={'ml-5'}>
                                        Cancelar
                                    </Button>
                                </div>

                            </Form>
                        </Modal>
                    </>
            }

        </>
    )
}